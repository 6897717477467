<template>
  <div class="item-page">
    <div class="item-page__header" v-if="loading">
      <v-skeleton-loader
          type="list-item-avatar-three-line"
          width="50%"
          height="100%"
      />
    </div>
    <div class="item-page__body" v-if="loading">
      <v-skeleton-loader
          type="list-item, list-item, list-item, list-item"
          width="50%"
          height="100%"
      />
      <v-skeleton-loader
          type="list-item-avatar, list-item-avatar"
          width="50%"
          height="100%"
      />
    </div>
    <div class="item-page__header" v-if="!loading">
      <img v-if="integration.type" :src="require(`@/assets/img/integrations/${integration.type}.svg`)" width="115"
           alt="" class="mr-9">
      <div class="item-page__name">
        <v-text-field
            class="evi-text-field"
            color="#44D370"
            v-model="integration.name"
            disabled
        >
          <template v-slot:label>
            Название интеграции
          </template>
        </v-text-field>
        <div class="item-page__actions mt-1">
          <v-btn text class="evi-button-text" @click="removeModal = true" v-if="hasRight('integrations.manage')">
            <img class="mr-2" :src="require('@/assets/img/users/red.svg')" alt="">
            Удалить
          </v-btn>
        </div>
      </div>
      <div class="item-page__close" @click="$router.go(-1)">
        <v-icon size="20">mdi-close</v-icon>
      </div>
    </div>
    <div class="item-page__body" v-if="!loading">
      <div class="item-page__edit">
        <v-autocomplete
            class="evi-autocomplete"
            color="#44D370"
            v-model="selectedUsers"
            :items="localUsers"
            item-text="name"
            item-value="id"
            placeholder="Выберите ответственных сотрудников"
            multiple
            :loading="userLoading"
            @focus="loadUsers"
            :search-input.sync="userSearch"
            @input.native="searchUser(userSearch)"
            cache-items
            clearable
            :disabled="!hasRight('integrations.manage')"
            @change="saveUsers"
            item-color="green"
        >
          <template v-slot:label>
            Выберите ответственных сотрудников
          </template>
          <template v-slot:append-item>
            <div v-intersect="userIntersect"/>
          </template>
          <template v-slot:item="{item}">
            <v-avatar
                color="#FFFFFF"
                size="30"
                class="user-page__avatar mr-3"
            >
              <img
                  :src="item.avatar_url ||require('@/assets/img/users/profile-avatar.png')"
                  alt=""
              >
            </v-avatar>
            <div class="v-list-item__title">{{ item.name }}</div>
          </template>
          <template v-slot:append>
                <span class="evi-select__arrow">
                    <v-icon>mdi-chevron-down</v-icon>
                </span>
          </template>
        </v-autocomplete>
        <v-autocomplete
            class="evi-autocomplete mb-5"
            color="#44D370"
            :items="localTags"
            v-model="selectedTags"
            multiple
            item-text="name"
            item-value="id"
            @change="saveTags"
            :readonly="!hasRight('integrations.manage')"
            :clearable="hasRight('integrations.manage')"
            item-color="green"
            return-object
            @focus="getTags"
        >
          <template v-slot:label>
            Добавочные теги
          </template>
          <template v-slot:append>
            <span class="evi-autocomplete__arrow">
                <v-icon>mdi-chevron-down</v-icon>
            </span>
          </template>
          <template v-slot:selection="{item}">
            <div class="evi-autocomplete__tag mr-2 mt-2"
                 :style="{backgroundColor: `${item.color}`, color: `${item.text_color}` }">
              {{ item.name }}
            </div>
          </template>
          <template v-slot:item="{item}">
            <div class="evi-autocomplete__tag"
                 :style="{backgroundColor: `${item.color}`, color: `${item.text_color}` }">
              {{ item.name }}
            </div>
          </template>
        </v-autocomplete>
        <div v-if="settings.integrations_clients_distribution">
          <h5 class="mb-5">
            Распределение клиентов
            <v-menu
                top
                offset-y
                open-on-hover
            >
              <template v-slot:activator="{ on, attrs }">
                <strong v-bind="attrs" v-on="on"><v-icon size="18" color="#289E80">mdi-information-outline</v-icon></strong>
              </template>
              <v-list-item class="user-color-status d-flex justify-space-between align-center pa-3">
                Включено автоматическое распределение клиентов.
                <br>
                Теги помогают распределять клиентов между сотрудниками или группами сотрудников.
                <br>
                Теги присваиваются клиентам поочередно.
              </v-list-item>
            </v-menu>
          </h5>
          <v-autocomplete
              class="evi-autocomplete mb-5"
              color="#44D370"
              :items="localTags"
              v-model="selectedDistributionTags"
              multiple
              item-text="name"
              item-value="id"
              @change="saveDistributionTags"
              :readonly="!hasRight('integrations.manage')"
              :clearable="hasRight('integrations.manage')"
              item-color="green"
              return-object
              @focus="getTags"
          >
            <template v-slot:label>
              Распределительные теги
            </template>
            <template v-slot:append>
            <span class="evi-autocomplete__arrow">
                <v-icon>mdi-chevron-down</v-icon>
            </span>
            </template>
            <template v-slot:selection="{item}">
              <div class="evi-autocomplete__tag mr-2 mt-2"
                   :style="{backgroundColor: `${item.color}`, color: `${item.text_color}` }">
                {{ item.name }}
              </div>
            </template>
            <template v-slot:item="{item}">
              <div class="evi-autocomplete__tag"
                   :style="{backgroundColor: `${item.color}`, color: `${item.text_color}` }">
                {{ item.name }}
              </div>
            </template>
          </v-autocomplete>
        </div>
      </div>
    </div>
    <v-dialog
        v-model="removeModal"
        max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          Удаление интеграции: "{{ integration.name }}"
        </v-card-title>
        <v-card-text>Вы действительно хотите удалить интеграцию?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="removeModal = false"
          >
            Отмена
          </v-btn>
          <v-btn
              color="red"
              text
              @click="remove"
          >
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {UserAutocompleteRequestsMixin} from "@/mixins/autocomplete/UserAutocompleteRequestsMixin";
import {hasRight} from '@/utils/access/hasRight';
import {mapActions, mapState} from "vuex";

export default {
  name: "IntegrationPage",
  mixins: [UserAutocompleteRequestsMixin],
  data: () => ({
    removeModal: false,
    selectedUsers: [],
    selectedTags: [],
    selectedDistributionTags: [],
  }),
  computed: {
    ...mapState("integrations", ['integration', 'settings']),
    ...mapState("tags", ['tags',]),
    ...mapState("common", ["loading"]),
    localUsers() {
      let array = [...this.selectedUsers];
      this.users.forEach(i => {
        if (!this.selectedUsers.some(e => e.id === i.id)) {
          array.push(i);
        }
      });
      return array;
    },
    localTags() {
      let array = [...this.selectedTags, ...this.selectedDistributionTags];
      this.tags.forEach(i => {
        array.push(i);
      });
      return array;
    },
  },
  methods: {
    ...mapActions({
      getIntegration: 'integrations/getIntegration',
      deleteIntegration: 'integrations/deleteIntegration',
      editIntegrationResponsibleUsers: 'integrations/editIntegrationResponsibleUsers',
      editIntegrationTags: 'integrations/editIntegrationTags',
      editIntegrationDistributionTags: 'integrations/editIntegrationDistributionTags',
      getTags: 'tags/getItemsBy',
    }),
    hasRight: hasRight,
    getData() {
      let id = this.$route.params.id;
      this.getIntegration(id);
    },
    saveUsers() {
      this.editIntegrationResponsibleUsers(
          {
            id: this.integration.id,
            selectedUsers: this.selectedUsers,
          }
      );
    },
    saveTags () {
      this.editIntegrationTags(
          {
            id: this.integration.id,
            selectedTags: this.selectedTags.map(i => i.id),
          }
      );
    },
    saveDistributionTags () {
      this.editIntegrationDistributionTags(
          {
            id: this.integration.id,
            selectedDistributionTags: this.selectedDistributionTags.map(i => i.id),
          }
      );
    },
    remove() {
      this.deleteIntegration(this.integration).then(() => {
        this.removeModal = false;
        this.$router.push({path: '/integrations'});
      });
    },
  },
  watch: {
    integration(val) {
      this.selectedUsers = val.users;
      this.selectedTags = val.tags;
      this.selectedDistributionTags = val.distribution_tags;
    },
    '$route.params.id': {
      handler: function () {
        this.getData();
      },
      deep: true,
      immediate: true
    },
  }
}
</script>

<style lang="scss" scoped>
.evi-autocomplete {
  max-width: 361px;
}
</style>
